export type LoginRoutes = {
  registerByPhone: string
  resetByEmail: string
  resetByPhone: string
  loginBySocialLogin(provider: any): string
  login(method: string): string
  forgetByPhone: string
  forgetByEmail: string
  saveFcm: string
  saveFcmByServer: string
}
export type CounterClickingRoutes = {
  clickingInCustomNotification(notificationId: any): string
  clickingInStoreAnnouncement(announcementId: any): string
  clickingInAnnouncement(announcementId: any): string
}
export type MaterialsDomainRoutes = {
  materials: string
  materialsByServer: string
  mostRequested: string
}
export type MaterialShippingRoutes = {
  materials: string
  materialsByServer: string
  mostRequested: string
}
export type MaterialDeliveringRoutes = {
  materials: string
  mostRequested: string
  materialsByServer: string
}
export type MaterialsRoutes = {
  wishlistMaterial: string
  wishlistMaterialByServer: string
  toggleFavServiceMaterial: string
  toggleFavServiceMaterialByServer: string
  materialShow(materialId: any): string
  materialRates(materialId: any): string
  materialShowByServer: string
  getMterialDefinitions: string
  rateMaterial(materialId: any): string
  rateMaterialByServer: string
  reportMaterial: string
  reportMaterialByServer: string
  materialGroupOffer: string
  materialGroupOfferByServer: string
  materialsByServer: string
  domainRoutes: MaterialsDomainRoutes
  shippingRoutes: MaterialShippingRoutes
  deliveringRoutes: MaterialDeliveringRoutes
}
export type StoresRoutes = {
  doseUserHaveTable(storeId: any): string
  storeShow(materialId: any): string
  storeShowByServer: string
  getStores: string
  getNearbyStores: string
  getNearbyStoresByServer: string
  getStoresByServer: string
  getStoresByMaterialName: string
  getStoreByStoreType(storeTypeId: any): string
}
export type MoneyMovementsRoutes = {
  getMoneyMovements: string
  getMoneyMovementsByServer: string
  getBalances: string
  getBalancesByServer: string
}
export type NotificationsRoutes = {
  makeAsRead(notificationId: any): string
  makeAsReadByServer: string
  getNotifications: string
  getNotificationsByServer: string
  getUndreadNotifications: string
  getUndreadNotificationsByServer: string
}

export type CitiesRoutes = {
  getCities: string
  getNeighborhoods: string
  getCitiesByServer: string
}
export type SettingsRoutes = {
  getSettings: string
  getSettingsByServer: string
  getSettingsWithGroupByServer: string
  getSettingsStore(storeId: any): string
  getSettingsStoreByServer: string
}
export type AnnouncementRoutes = {
  announcementsStore(storeId: any): string
  getAnnouncementsByServer: string
  getAnnouncements: string
}

export type ProfileRoutes = {
  profile: string
  updateProfile: string
  updateProfileByServer: string
  verifyPhone: string
  verifyPhoneByServer: string
  deleteInstanceId: string
  deleteInstanceIdByServer: string
  verifyEmail: string
  verifyEmailByServer: string
  profileByServer: string
  reSendMailVerificationCode: string
  reSendMailVerificationCodeByServer: string
  reSendSMSVerificationCode: string
  reSendSMSVerificationCodeByServer: string
}
export type CategoriesRoutes = {
  categoryShow(categoryId: any): string
  categoryShowByServer(categoryId: any): string
  categories: string
  categoriesByServer: string
}
export type StoreTypeRoutes = {
  getStoresType: string
  getStoresTypeByServer: string
}

export type CartRoutes = {
  addMaterialWithAddon: string
  addMaterialWithAddonWithToggle: string
  addMaterial: string
  addMaterialWithToggle: string
  addBill: string
  addBillByServer: string

  cart: string
  setTableToStore(storeId: any): string
  setTableToStoreWithToggle(storeId: any): string
  removeMaterialsStoreCart(agentPlaceId: any): string
  removeMaterialsStoreCartByServer: string
  removeMaterialsStoreCartWithToggle(storeId: any): string
  removeFromCart: string
  removeFromCartByServer: string
  removeFromCartWithToggle: string
  getShippingCostWithTime: string
  getShippingCostWithTimeByServer: string
  getIsCartShouldBeShipped(storeId: any, agentPlaceId: any): string
  getIsCartShouldBeShippedByServer: string
  editCartOffer: string
  editCartOfferWithToggle: string
  editMaterialFromCart: string
  editMaterialFromCartWithToggle: string
  editAddonsCart(cartId: any): string
  editAddonsCartWithToggle(cartId: any): string
  getMaterialsCount: string
  getMaterialsCountWithToggle: string
  getCartWithToggle: string
  clearCartWithToggle: string
}
export type PlacesRoutes = {
  agentPlaceByServer: string
  agentPlace: string
  convertGoogleAddressToLocal: string
  newAgentPlaceByServer: string
  deleteAgentPlace(agentPlaceId: any): string
  deleteAgentPlaceByServer: string
  updateAgentPlace: string
  updateAgentPlaceByServer: string
}
export type BillsRoutes = {
  rateBill: string
  rateBillByServer: string
  getShowBill(agentPlaceId: any): string
  getShowBillByServer: string
  getBills: string
  getBillsByServer: string
}
export type AllRoutes = {
  loginRoutes: LoginRoutes
  profileRoutes: ProfileRoutes
  cartRoutes: CartRoutes
  billsRoutes: BillsRoutes
  placesRoutes: PlacesRoutes
  announcementRoutes: AnnouncementRoutes
  materialsRoutes: MaterialsRoutes
  storesRoutes: StoresRoutes
  counterClickingRoutes: CounterClickingRoutes
  moneyMovement: MoneyMovementsRoutes
  notificationsRoutes: NotificationsRoutes
  categoriesRoutes: CategoriesRoutes
  storeTypeRoutes: StoreTypeRoutes
  citiesRoutes: CitiesRoutes
  settingsRoutes: SettingsRoutes
}

export default function getAllRoutes(isAuthLoggedIn?: boolean): AllRoutes {
  const serverRoutesPath = '/nuxt-api/'
  return {
    moneyMovement: {
      getMoneyMovements: '/money-movement',
      getBalances: '/agent/getBalanceForAgentForAllCurrency',
      getBalancesByServer: `${serverRoutesPath}user/balances`,
      getMoneyMovementsByServer: `${serverRoutesPath}user/moneyMovement`
    },
    counterClickingRoutes: {
      clickingInCustomNotification: (customNotificationId) =>
        `/add_click_to_custom_notification/${customNotificationId}`,
      clickingInStoreAnnouncement: (announcementId) =>
        `/add_click_to_store_announcement/${announcementId}`,
      clickingInAnnouncement: (announcementId) =>
        `/add_click_to_announcement/${announcementId}`
    },
    billsRoutes: {
      getBills: '/bill',
      rateBill: '/agent/rateBill',
      getShowBill: (billId) => `/bill/${billId}`,
      getBillsByServer: `${serverRoutesPath}user/bills`,
      getShowBillByServer: `${serverRoutesPath}user/bills/show`,
      rateBillByServer: `${serverRoutesPath}user/bills/rate`
    },
    notificationsRoutes: {
      getNotifications: '/notifications',
      getUndreadNotifications: '/notifications/numberUnread',
      makeAsRead: (notificationId) =>
        `/notifications/makeAsRead/${notificationId}`,
      getNotificationsByServer: `${serverRoutesPath}user/notifications`,
      getUndreadNotificationsByServer: `${serverRoutesPath}user/notifications/unread`,
      makeAsReadByServer: `${serverRoutesPath}user/notifications/mark-as-read`
    },
    placesRoutes: {
      agentPlace: '/agentPlace',
      convertGoogleAddressToLocal: '/convertGoogleAddressToLocal',
      agentPlaceByServer: `${serverRoutesPath}user/agentPlace`,
      newAgentPlaceByServer: `${serverRoutesPath}user/agentPlace/createAgentPlace`,
      deleteAgentPlace: (agentPlaceId) => `/agentPlace/${agentPlaceId}`,
      deleteAgentPlaceByServer: `${serverRoutesPath}user/agentPlace/deleteAgentPlace`,
      updateAgentPlace: '/cart/update-agent-place',
      updateAgentPlaceByServer: `${serverRoutesPath}user/agentPlace/updateAgentPlace`
    },
    storesRoutes: {
      getStores: '/stores',
      getNearbyStoresByServer: `${serverRoutesPath}user/bills/getNearbyStores`,
      getNearbyStores: '/get_nearby_stores',
      getStoresByMaterialName:
        '/v1/delivering_materials/getStoresByMaterialName',
      doseUserHaveTable: (storeId) => `/cart/${storeId}/dose-user-have-table`,
      getStoreByStoreType: (storeId) => `/get_store_by_store_type/${storeId}`,
      getStoresByServer: `${serverRoutesPath}laravel/stores`,
      storeShow: (storeId) => `/stores/${storeId}`,
      storeShowByServer: `${serverRoutesPath}laravel/store`
    },
    storeTypeRoutes: {
      getStoresType: '/storeType',
      getStoresTypeByServer: `${serverRoutesPath}/laravel/store-types`
    },
    categoriesRoutes: {
      categories: '/category',
      categoriesByServer: `${serverRoutesPath}laravel/category`,
      categoryShow: (categoryId) => `/category/${categoryId}`,
      categoryShowByServer: (categoryId) =>
        `${serverRoutesPath}laravel/category/${categoryId}`
    },
    citiesRoutes: {
      getCitiesByServer: `${serverRoutesPath}laravel/settings/cities`,
      getCities: '/cities',
      getNeighborhoods: '/neighborhoods'
    },
    settingsRoutes: {
      getSettingsByServer: `${serverRoutesPath}laravel/settings`,
      getSettingsWithGroupByServer: `${serverRoutesPath}laravel/settings/group`,
      getSettings: '/setting',
      getSettingsStoreByServer: `${serverRoutesPath}laravel/settings/group`,
      getSettingsStore: (storeId) => `/getStoresSettings/${storeId}`
    },
    announcementRoutes: {
      announcementsStore: (storeId) => `/announcements-store/${storeId}`,
      getAnnouncements: '/announcements',
      getAnnouncementsByServer: `${serverRoutesPath}laravel/announcements`
    },
    cartRoutes: {
      addMaterialWithAddon: '/cart/new-item-with-addon',
      addMaterialWithAddonWithToggle: isAuthLoggedIn
        ? `${serverRoutesPath}user/cart/addAddonsCart`
        : '/cart/new-item-with-addon',
      addMaterial: '/cart/new-item',
      addMaterialWithToggle: isAuthLoggedIn
        ? `${serverRoutesPath}user/cart/addMaterial`
        : '/cart/new-item',
      addBill: '/bill',
      addBillByServer: `${serverRoutesPath}user/cart/bill`,
      cart: '/cart',
      getCartWithToggle: isAuthLoggedIn
        ? `${serverRoutesPath}user/cart/getCart`
        : '/cart',
      clearCartWithToggle: isAuthLoggedIn
        ? `${serverRoutesPath}user/cart/clearCart`
        : '/cart',
      getMaterialsCount: '/cart/getMaterialsCount',
      getMaterialsCountWithToggle: isAuthLoggedIn
        ? `${serverRoutesPath}user/cart/count`
        : '/cart/getMaterialsCount',
      editAddonsCart: (cartId: any) => `/cart/${cartId}/addons`,
      editAddonsCartWithToggle: (cartId: any) =>
        isAuthLoggedIn
          ? `${serverRoutesPath}user/cart/editAddonsCart`
          : `/cart/${cartId}/addons`,
      editMaterialFromCart: '/cart/editMaterialFromCart',
      editMaterialFromCartWithToggle: isAuthLoggedIn
        ? `${serverRoutesPath}user/cart/editCart`
        : '/cart/editMaterialFromCart',
      editCartOffer: '/cart/material-group-offer',
      editCartOfferWithToggle: isAuthLoggedIn
        ? `${serverRoutesPath}user/cart/editCartOffer`
        : '/cart/material-group-offer',
      getShippingCostWithTime: '/cart/get_cart_shipping_cost',
      getShippingCostWithTimeByServer: `${serverRoutesPath}user/cart/getShippingCostWithTime`,
      getIsCartShouldBeShipped: (storeId: any, agentPlaceId: any) =>
        `/cart/getIsCartShouldBeShipped/${storeId}/${agentPlaceId}`,
      getIsCartShouldBeShippedByServer: `${serverRoutesPath}user/cart/getIsCartShouldBeShipped`,
      removeFromCart: '/cart/deleteMaterialFromCart',
      removeFromCartByServer: `${serverRoutesPath}user/cart/removeFromCart`,
      removeFromCartWithToggle: isAuthLoggedIn
        ? `${serverRoutesPath}user/cart/removeFromCart`
        : '/cart/deleteMaterialFromCart',
      removeMaterialsStoreCart: (storeId: any) => `/cart/store/${storeId}`,
      removeMaterialsStoreCartByServer: `${serverRoutesPath}user/cart/removeMaterialsStoreCart`,
      removeMaterialsStoreCartWithToggle: (storeId: any) =>
        isAuthLoggedIn
          ? `${serverRoutesPath}user/cart/removeMaterialsStoreCart`
          : `/cart/store/${storeId}`,
      setTableToStore: (storeId: any) => `/cart/assignTableToStore/${storeId}`,
      setTableToStoreWithToggle: (storeId: any) =>
        isAuthLoggedIn
          ? `${serverRoutesPath}user/cart/setTableToStore`
          : `/cart/assignTableToStore/${storeId}`
    },
    materialsRoutes: {
      getMterialDefinitions: '/material-definitions',
      materialShow: (materialId) => `/material/${materialId}`,
      materialRates: (materialId: any) => `/material/${materialId}/getRatings`,
      materialShowByServer: isAuthLoggedIn
        ? `${serverRoutesPath}user/materials/material`
        : `${serverRoutesPath}laravel/materials/material-show`,
      rateMaterial: (materialId) => `/material/${materialId}/review`,
      rateMaterialByServer: `${serverRoutesPath}user/materials/rate`,
      reportMaterial: '/agent/reportService',
      reportMaterialByServer: `${serverRoutesPath}user/materials/report`,
      materialGroupOffer: '/MaterialGroupOffer',
      materialGroupOfferByServer: `${serverRoutesPath}laravel/materials/MaterialGroupOffer`,
      materialsByServer: `${serverRoutesPath}laravel/materials`,
      wishlistMaterial: '/agent/getFavoriteMaterial',
      wishlistMaterialByServer: `${serverRoutesPath}user/materials/wishlist`,
      toggleFavServiceMaterial: '/agent/toggleFavService',
      toggleFavServiceMaterialByServer: `${serverRoutesPath}user/materials/toggleFavService`,
      domainRoutes: {
        materialsByServer: `${serverRoutesPath}laravel/materials/materials-domain`,
        materials: '/v1/domain_material',
        mostRequested: '/v1/domain_material/get-top-material-for-each-category'
      },
      shippingRoutes: {
        materials: '/v1/shipping_material',
        materialsByServer: `${serverRoutesPath}laravel/materials/materials-shipping`,
        mostRequested: '/shipping_material/get-top-material-for-each-category'
      },
      deliveringRoutes: {
        materialsByServer: `${serverRoutesPath}laravel/materials/materials-delivering`,
        materials: '/v1/delivering_material',
        mostRequested:
          '/v1/delivering_material/get-top-material-for-each-category'
      }
    },
    loginRoutes: {
      login: (method) => `/user/loginBy${method}`,
      saveFcm: '/user/saveInstanceId',
      saveFcmByServer: `${serverRoutesPath}user/notifications/save-fcm-token`,
      registerByPhone: '/user/extendedRegisterByPhone',
      forgetByPhone: '/user/forgetByPhone',
      resetByEmail: '/user/resetByEmail',
      resetByPhone: '/user/resetByPhone',
      forgetByEmail: '/user/forgetByEmail',
      loginBySocialLogin: (provider) => `/user/callBack_socialLogin/${provider}`
    },
    profileRoutes: {
      verifyPhone: '/user/verifyPhone',
      profile: '/user/profile',
      profileByServer: `${serverRoutesPath}user/profile`,
      verifyPhoneByServer: `${serverRoutesPath}auth/verify-phone`,
      updateProfile: '/profile_update',
      verifyEmail: '/user/verifyEmail',
      verifyEmailByServer: `${serverRoutesPath}auth/verify-email`,
      updateProfileByServer: `${serverRoutesPath}user/profile/update`,
      reSendMailVerificationCode: '/user/reSendMailVerificationCode',
      reSendMailVerificationCodeByServer: `${serverRoutesPath}auth/resend-email-verification`,
      reSendSMSVerificationCode: '/user/reSendSMSVerificationCode',
      reSendSMSVerificationCodeByServer: `${serverRoutesPath}auth/resend-phone-verification`,
      deleteInstanceId: '/user/deleteInstanceId',
      deleteInstanceIdByServer: `${serverRoutesPath}user/profile/delete-instance-id`
    }
  } as AllRoutes
}
