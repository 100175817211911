import { useToast } from 'vue-toastification'

import { initializeApp } from 'firebase/app'

import { getMessaging, getToken } from 'firebase/messaging'
import type { RouteLocationNormalizedLoaded } from '#vue-router'
import getAllRoutes from './AppApiRoutes'
import {
  useApiErrorsStore,
  fetchToApiError,
  type ApiError
} from '~~/store/api_errors'
const loginRoutes = getAllRoutes().loginRoutes
const profileRoutes = getAllRoutes().profileRoutes
export type LoginMethod = 'Email' | 'Phone'
async function gotoRouteAfterLogin(route: RouteLocationNormalizedLoaded) {
  const { setFcmToken } = useSetFcmToken()
  try {
    await setFcmToken()
  } catch (error) {}
  window.history.replaceState({}, document.title, '/')
  reloadNuxtApp({
    path: route.query.callbackUrl?.toString() || '/my-account',
    ttl: 1
  })
}
export const useLogin = () => {
  const { t } = useNuxtApp().$i18n

  const form = reactive({
    email: '',
    phone: '',
    password: '',
    rememberMe: false,
    method: 'Email',
    loading: false
  })

  const signInHandler = async () => {
    const route = useRoute()
    const { signIn } = useAuth()
    const { addApiError, deleteApiError } = useApiErrorsStore()

    deleteApiError('login')
    form.loading = true

    try {
      await signIn(
        {
          url: useBaseUrl(loginRoutes.login(form.method)),
          email: form.method === 'Email' ? form.email : '',
          phone: form.method === 'Phone' ? form.phone.replace(/\s+/g, '') : '',
          password: form.password,
          rememberMe: form.rememberMe
        },
        {
          callbackUrl: route.query.callbackUrl?.toString() || '/my-account',
          redirect: false
        }
      )

      await gotoRouteAfterLogin(route)
      form.loading = false
    } catch (error) {
      form.loading = false
      // @ts-ignore
      if (error?.response?.status === 400) {
        addApiError(
          { message: t('bad_credentials', { action: t('login') }) },
          'login'
        )
      } else {
        addApiError(fetchToApiError(error), 'login')
      }
    }
  }

  return {
    form,
    signInHandler
  }
}

export const useSocialLogin = () => {
  const route = useRoute()

  const signInHandler = async () => {
    const { signIn } = useAuth()
    const { addApiError, deleteApiError } = useApiErrorsStore()
    const token = route.hash.split('&')[0].replace('#access_token=', '')

    deleteApiError('login')

    try {
      await useBasicFetch(
        useBaseUrl(loginRoutes.loginBySocialLogin(route.params.provider)),
        {
          method: 'POST',
          body: {
            access_token: token,
            locale: useNuxtApp().$i18n?.locale?.value
          }
        }
      )

      // this.$auth.setStrategy("local");
      // this.$auth.setUserToken(data.data.access_token);
      // this.$auth.setUser(data.data.user);
    } catch (error) {
      return addApiError(fetchToApiError(error), 'login')
    }

    const { error: loginError, url } = await signIn(
      {
        // url: "/user/loginBy" + form.method,
        // email: form.method === "Email" ? form.email : "",
        // phone: form.method === "Phone" ? form.phone : "",
        // password: form.password,
        // rememberMe: form.rememberMe,
      },
      {
        // callbackUrl: route.query.callbackUrl?.toString() || "/",
        // redirect: false,
      }
    )

    if (loginError) {
      addApiError(fetchToApiError(loginError), 'login')
    } else {
      return navigateTo(url, { external: true })
    }
  }

  return {
    signInHandler
  }
}

export const useSetFcmToken = () => {
  const getFCMSettings = async () => {
    const { url } = useDomainHost()
    const res = await fetch(url + '/js/firebase_config.js')
    if (res.ok) {
      const strConfig = (await res.text())
        ?.replace('const firebaseConfig = ', '')
        ?.replace(';', '')
      const objConfig = eval('(' + strConfig + ')')
      const firebaseApp = initializeApp(objConfig)
      const messaging = getMessaging(firebaseApp)

      return messaging
    }
  }
  const fcmToken = ref<string | undefined>()
  const setFcmToken = async () => {
    if (fcmToken.value === undefined) {
      const messaging = await getFCMSettings()
      if (messaging) {
        const fcmTokenTemp = await getToken(messaging)

        if (fcmTokenTemp) {
          try {
            await useBasicFetch(loginRoutes.saveFcmByServer, {
              method: 'POST',
              body: {
                //    headers: authPro, // for use auth in localHost
                token: fcmTokenTemp,
                token_type: 'FIREBASE_WEB'
              }
            })
            fcmToken.value = fcmTokenTemp
            return messaging
          } catch (error) {}
        }
      }
    }
    return null
  }
  return {
    setFcmToken
  }
}
/* const authDev = {
  Authorization:
    'Bearer ' +
    '1159|LDw0vhix9aJINvF1udANCs14vn27K8AIFOM4wEOj471986e2'
}
const authPro = {
  Authorization:
    'Bearer ' +
    '313|1OaJng6irInKEwuOZeX2QSKoVoKVyoqb7ygfUayi8de7450f'
} */
export const useRegister = () => {
  const { t } = useNuxtApp().$i18n
  const route = useRoute()

  const form = reactive({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    city_id: undefined as number | undefined,
    password: '',
    passwordConfirmation: '',
    terms: false,
    loading: false
  })

  const registerHandler = async () => {
    const { signIn } = useAuth()
    const { addApiError, deleteApiError } = useApiErrorsStore()

    deleteApiError('register')
    const errors: ApiError = {
      message: '',
      errors: {}
    }
    if (!form.terms) {
      errors.errors!.terms = [t('must_accept_terms')]
    }
    if (!form.first_name.trim().length) {
      errors.errors!.first_name = [t('required', { name: t('first_name') })]
    }
    if (!form.last_name.trim().length) {
      errors.errors!.last_name = [t('required', { name: t('last_name') })]
    }
    if (!form.city_id) {
      errors.errors!.city_id = [t('required', { name: t('city') })]
    }
    if (Object.values(errors.errors!).length) {
      return addApiError(errors, 'register')
    }

    form.loading = true

    try {
      await signIn(
        {
          url: useBaseUrl(loginRoutes.registerByPhone),
          email: form.email,
          last_name: form.last_name,
          first_name: form.first_name,
          city_id: form.city_id,
          phone: form.phone.replace(/\s+/g, ''),
          password: form.password,
          password_confirmation: form.passwordConfirmation
        },
        {
          callbackUrl: route.query.callbackUrl?.toString() || '/my-account',
          redirect: false
        }
      )
      await gotoRouteAfterLogin(route)

      form.loading = false
    } catch (error) {
      form.loading = false

      addApiError(fetchToApiError(error), 'register')
    }
  }

  return {
    form,
    registerHandler
  }
}

export const useForgot = () => {
  const { t } = useNuxtApp().$i18n
  const router = useRouter()

  const form = reactive({
    email: '',
    phone: '',
    method: 'Email',
    loading: false
  })

  const forgotHandler = async () => {
    const { addApiError, deleteApiError } = useApiErrorsStore()

    deleteApiError('forgot')
    form.loading = true

    try {
      await useBasicFetch(
        form.method === 'Email'
          ? loginRoutes.forgetByEmail
          : loginRoutes.forgetByPhone,
        {
          method: 'POST',
          body: {
            app_locale: useNuxtApp().$i18n?.locale?.value,
            email: form.method === 'Email' ? form.email : '',
            phone: form.method === 'Phone' ? form.phone.replace(/\s+/g, '') : ''
          }
        }
      )

      if (form.method === 'Email') {
        const toast = useToast()
        toast.success(t('email_link_sent', { action: t('the_reset') }))
      } else {
        const localePath = useLocalePath()
        router.replace(
          localePath({
            path: '/auth/phone-reset-password',
            query: { phone: form.phone.replace(/\s+/g, '') }
          })
        )
      }

      form.loading = false
    } catch (error) {
      form.loading = false

      addApiError(fetchToApiError(error), 'forgot')
    }
  }

  return {
    form,
    forgotHandler
  }
}

export const useResetByEmail = () => {
  const { t } = useNuxtApp().$i18n
  const route = useRoute()

  const form = reactive({
    email: route.query.email as string,
    password: '',
    passwordConfirmation: '',
    loading: false
  })

  const resetHandler = async () => {
    const { addApiError, deleteApiError } = useApiErrorsStore()
    const { form: formLogin, signInHandler } = useLogin()
    deleteApiError('email_reset')
    form.loading = true

    try {
      await useBasicFetch(loginRoutes.resetByEmail, {
        method: 'POST',
        body: {
          app_locale: useNuxtApp().$i18n?.locale?.value,
          token: route.query.token,
          client_id: 'this.client_id',
          client_secret: 'this.client_secret',
          email: form.email,
          password: form.password,
          password_confirmation: form.passwordConfirmation
        }
      })
      const toast = useToast()
      toast.success(t('password_reset_done'))
      formLogin.email = form.email
      formLogin.password = form.password
      formLogin.rememberMe = true
      formLogin.method = 'Email'
      await signInHandler()
    } catch (error) {
      form.loading = false

      addApiError(fetchToApiError(error), 'email_reset')
    }
  }

  return {
    form,
    resetHandler
  }
}

export const useResetByPhone = () => {
  const { t } = useNuxtApp().$i18n
  const route = useRoute()

  const form = reactive({
    phone: route.query.phone?.toString().replace(/\s+/g, '') || '',
    code: '',
    password: '',
    passwordConfirmation: '',
    loading: false,
    resendLoading: false,
    remain: 0
  })

  let timer: NodeJS.Timer

  const resendHandler = async () => {
    const { addApiError, deleteApiError } = useApiErrorsStore()

    clear()
    deleteApiError('phone_reset')
    form.resendLoading = true

    try {
      await useBasicFetch(loginRoutes.forgetByPhone, {
        method: 'POST',
        body: {
          phone: form.phone.replace(/\s+/g, ''),
          locale: useNuxtApp().$i18n?.locale?.value
        }
      })

      form.resendLoading = false
    } catch (error) {
      console.log(error)
      form.resendLoading = false

      addApiError(fetchToApiError(error), 'phone_reset')

      if ((error as any)?.data?.data) {
        form.remain = (error as any)?.data?.data

        if (form.remain) {
          timer = setInterval(() => {
            if (form.remain > 0) {
              form.remain--
            } else {
              clear()
            }
          }, 1000)
        }
      }
    }
  }

  const resetHandler = async () => {
    const { addApiError, deleteApiError } = useApiErrorsStore()
    const { form: formLogin, signInHandler } = useLogin()
    clear()
    deleteApiError('phone_reset')
    form.loading = true

    try {
      await useBasicFetch(loginRoutes.resetByPhone, {
        method: 'POST',
        body: {
          client_id: 'this.client_id',
          client_secret: 'this.client_secret',
          phone: form.phone.replace(/\s+/g, ''),
          code: form.code,
          app_locale: useNuxtApp().$i18n?.locale?.value,
          password: form.password,
          password_confirmation: form.passwordConfirmation
        }
      })

      form.loading = false
      const toast = useToast()
      toast.success(t('password_reset_done'))
      formLogin.method = 'Phone'
      formLogin.phone = form.phone
      formLogin.password = form.password
      formLogin.rememberMe = true
      await signInHandler()
    } catch (error) {
      form.loading = false

      addApiError(fetchToApiError(error), 'phone_reset')
    }
  }

  const clear = () => clearInterval(timer)

  return {
    form,
    resendHandler,
    resetHandler,
    clear
  }
}

export const useVerifyEmail = () => {
  const toast = useToast()
  const { t } = useI18n()
  const { status } = useAuth()
  const router = useRouter()
  const route = useRoute()

  const form = reactive({
    verifying: false,
    sending: false,
    failed: false
  })

  const resendHandler = async () => {
    if (form.sending) {
      return
    }

    const { addApiError, deleteApiError } = useApiErrorsStore()
    deleteApiError('verify_email')
    if (status.value !== 'authenticated') {
      toast.error(t('login_required'))
      return
    }
    form.sending = true
    form.failed = false

    try {
      await useBasicFetch(profileRoutes.reSendMailVerificationCodeByServer, {
        method: 'POST',
        body: {
          app_locale: useNuxtApp().$i18n?.locale?.value
        }
      })

      form.sending = false

      toast.success(t('email_link_sent', { action: t('the_verify') }))
    } catch (error) {
      form.sending = false
      form.failed = true
      addApiError(fetchToApiError(error), 'verify_email')
    }
  }

  const verifyHandler = async () => {
    if (form.verifying) {
      return
    }

    const { addApiError, deleteApiError } = useApiErrorsStore()

    deleteApiError('verify_email')
    form.verifying = true
    form.failed = false

    try {
      const { error } = await useBasicFetch(profileRoutes.verifyEmail, {
        method: 'POST',
        body: {
          id: route.query.id,
          hash: route.query.hash,
          app_locale: useNuxtApp().$i18n?.locale?.value
        }
      })
      if (error.value) {
        form.verifying = false
        form.failed = true
        addApiError(fetchToApiError(error.value), 'verify_email')
      } else {
        await useAuth().getSession()
        form.verifying = false
        toast.success(t('verify_done', { name: t('email') }))
        router.replace('/my-account')
      }
    } catch (error) {
      form.verifying = false
      form.failed = true

      addApiError(fetchToApiError(error), 'verify_email')
    }
  }

  return {
    form,
    resendHandler,
    verifyHandler
  }
}

export const useVerifyPhone = () => {
  const { t } = useNuxtApp().$i18n
  const form = reactive({
    code: '',
    verifying: false,
    sending: false,
    sent: false,
    verified: false,
    remain: 0
  })

  let timer: NodeJS.Timer

  const validCode = computed(
    () => !form.verifying && form.code.match(/^\d{4}$/)
  )

  const resendHandler = async () => {
    const { addApiError, deleteApiError } = useApiErrorsStore()

    clear()
    deleteApiError('verify_phone')
    form.sending = true

    try {
      await useBasicFetch(profileRoutes.reSendSMSVerificationCodeByServer, {
        method: 'POST',
        body: {
          app_locale: useNuxtApp().$i18n?.locale?.value
        }
      })

      form.sending = false
      form.sent = true
    } catch (error) {
      form.sending = false

      addApiError(fetchToApiError(error), 'verify_phone')

      if ((error as any)?.data?.data?.data) {
        form.remain = (error as any)?.data?.data?.data

        if (form.remain) {
          timer = setInterval(() => {
            if (form.remain > 0) {
              form.remain--
            } else {
              clear()
            }
          }, 1000)
        }
      }
    }
  }

  const verifyHandler = async () => {
    if (!validCode) {
      return
    }

    const { addApiError, deleteApiError } = useApiErrorsStore()

    deleteApiError('verify_phone')
    form.verifying = true

    try {
      await useBasicFetch(profileRoutes.verifyPhoneByServer, {
        method: 'POST',
        body: { code: form.code, app_locale: useNuxtApp().$i18n?.locale?.value }
      })

      await useAuth().getSession()

      form.verifying = false
      const toast = useToast()
      toast.success(t('verify_done', { name: t('phone') }))
      return true
    } catch (error) {
      form.verifying = false

      addApiError(fetchToApiError(error), 'verify_phone')
      return false
    }
  }

  const clear = () => clearInterval(timer)

  return {
    form,
    validCode,
    resendHandler,
    verifyHandler,
    clear
  }
}

export const useProfile = () => {
  const { t } = useNuxtApp().$i18n
  const { data } = useAuth()

  const form = reactive<Record<string, any>>({
    first_name: data.value?.first_name || '',
    last_name: data.value?.last_name || '',
    email: data.value?.email || '',
    phone: data.value?.phone || '',
    old_password: '',
    city_id: data.value?.city_id,
    password: '',
    app_locale: useNuxtApp().$i18n?.locale?.value,
    password_confirmation: '',
    profile_image: null,
    loading: false
  })

  const updateHandler = async () => {
    if (form.loading) {
      return
    }

    const { addApiError, deleteApiError } = useApiErrorsStore()

    deleteApiError('profile')
    form.loading = true

    try {
      const formData = new FormData()

      for (const key in form) {
        if (Object.prototype.hasOwnProperty.call(form, key)) {
          formData.append(key, form[key])
        }
      }

      const { error } = await useBasicFetch(
        profileRoutes.updateProfileByServer,
        {
          method: 'POST',
          body: formData
        }
      )
      form.loading = false
      if (error.value) {
        addApiError(fetchToApiError(error.value), 'profile')
      } else {
        await useAuth().getSession()
        const toast = useToast()
        toast.success(t('edit_done', { name: t('profile') }))
      }
    } catch (error) {
      form.loading = false

      addApiError(fetchToApiError(error), 'profile')
    }
  }

  const getProfile = async () => {
    const { status, getSession } = useAuth()
    if (status.value == 'authenticated') {
      await useBasicFetch(profileRoutes.profileByServer)
      await getSession()
    }
  }
  return {
    session: data,
    form,
    getProfile,
    updateHandler
  }
}
export const useProfileId = () => {
  const { data } = useAuth()
  return data.value?.id
}
